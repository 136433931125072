import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = props => (
  <Layout location={props.location}>
    <SEO title="Home - Coastal Advisory Group" description="You can't direct the wind, but you can adjust the sails... same wisdom that Coastal applies to wealth management for our select clients..."/>
    <h1 style={styles.header}>Wealth Management to Steer<br/> Your Financial Future...</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Servicing Select Clients throughout Greater Cincinnati and from Around the Country</h3>
      <p>
        There’s an old sailing adage that claims “You can’t direct the wind, but you can adjust the sails.” This is the 
        same wisdom that the Coastal Advisory Group applies to wealth management for our select clients.<br/>
        When you’re a member of our family of investors, we monitor the environment and make adjustments to help ensure 
        that your investments are moving toward your desired destination.
      </p>
      <p style={styles.secondaryText}>
        Our Select Clients Get Smoother Sailing from Highly Personal Service and Investment Strategy
      </p>
      <p>
        Like any sailor, we like to brag — and our biggest boast is we believe that our client retention rate is the envy of the industry. That means in the worst economic storm in recent history, our clients stayed right on board with us. Here’s why:
      </p>
      <p style={styles.bullet}>
        <span style={styles.secondaryText}>Our Belief</span> that great friendship is born from honesty and trust, and successful Client-Advisor relationships come from the exact same place.
      </p>
      <p style={styles.bullet}>
        <span style={styles.secondaryText}>First Hand Knowledge of the Business World.</span> Michael Mueller, the Captain of our Ship, combines his life experience with rigorous research guiding asset selection that others may overlook.
      </p>
      <p style={styles.bullet}>
        <span style={styles.secondaryText}>A Tailored Investment Strategy</span> designed to fit your unique goals and needs.
      </p>
      <p style={styles.bullet}>  
        <span style={styles.secondaryText}>Active Management of Accounts</span> by monitoring your investments and making adjustments to keep you on course to your dreams.
      </p>
      <p style={styles.bullet}>  
        <span style={styles.secondaryText}>Multi-Generational Fees</span> discounted on the total value of the entire family’s investments, with each member paying their proportionate share based on their own investment accounts.
      </p>
      <p style={styles.bullet}>  
        <span style={styles.secondaryText}>Highly Personalized Service</span> that goes much deeper than the typical advisor-client relationship and enables us to make a real difference in how you manage your wealth.
      </p>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default IndexPage
